import GithubCard from "../misc/GithubCard.tsx";

import "../stylesheets/styles.css";

export default function Projects() {
  return (
    <>
      <div className={"card-container"}>
        <h1>Projects</h1>
        <GithubCard repo={"9964A-PROSject"} username={"HiddenAbilitree"} />
        <GithubCard repo={"old-roblox-scripts"} username={"HiddenAbilitree"} />
        <GithubCard repo={"sbcshs-website"} username={"SBCSHS"} />
      </div>
    </>
  );
}
