import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrog } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { Link, Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <Header />
      <Footer />
    </>
  );
}

function Header() {
  return (
    <>
      <nav>
        <ul>
          <li>
            <Link to="/">
              <FontAwesomeIcon icon={faFrog} />
            </Link>
          </li>
          <li>
            <Link to="/aboutme"> About Me </Link>
          </li>
          <li>
            <Link to="/projects"> Projects </Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </>
  );
}

function Footer() {
  return (
    <>
      <footer>
        <ul>
          <li>
            <div className="title">Website by Eric Zhang</div>
          </li>
          <li>
            <a href="https://github.com/HiddenAbilitree">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}
