import "../stylesheets/styles.css";

export default function About() {
  return (
    <>
      <section>
        <h1>About Me</h1>
        <p>
          I am a first year Computer Science student heading into my junior year
          (in credits) at George Mason.
        </p>
        <p>I enjoy everything about computers.</p>
      </section>
    </>
  );
}
