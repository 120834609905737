import { useState } from "react";
const Testing = () => {
  const [output, setOutput] = useState("Hello");
  return (
    <>
      <section>
        <p>{output.concat(" Test")}</p>
        <form
          className="background-color: var(--dark-primary);"
          onSubmit={(e) => {
            e.preventDefault();
            setOutput(e.target[0].value);
          }}
        >
          <label>Input something: </label>
          <input type="text"></input>
        </form>
      </section>
    </>
  );
};
export default Testing;
