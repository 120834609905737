import { useEffect, useState } from "react";

import styles from "../stylesheets/githubcard.module.css";
import { faCodeFork, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type repoInformation = {
  username: string;
  repo: string;
};
export default function GithubCard({ username, repo }: repoInformation) {
  const repoURL = `https://api.github.com/repos/${username}/${repo}`;
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetch(repoURL, { method: "GET" })
      .then((response) => response.json())
      .then((json) => {
        setData(json);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError("Error fetching data");
        setLoading(false);
      });
  }, [repoURL]);

  if (loading) {
    return <div className={styles.github_card}>Loading...</div>;
  }

  if (error) {
    return <div className={styles.github_card}>Error: {error}</div>;
  }

  return (
    <>
      <div className={styles.github_card}>
        <a href={"https://github.com/" + username + "/" + repo}>
          {data ? (
            <>
              <div className={styles.info}>
                <div className={styles.title}>
                  {username + "/"}
                  <br />
                  <b>{repo}</b>
                </div>
                <div className={styles.avatar}>
                  <img
                    src={data.owner.avatar_url}
                    alt={username + "s avatar"}
                    width="50"
                    height="50"
                  />
                </div>
              </div>
              <div className={styles.stats}>
                <div className={styles.language}>{data.language}</div>
                <div className={styles.stars}>
                  <FontAwesomeIcon icon={faStar} />
                  {data.stargazers_count}
                </div>
                <div className={styles.forks}>
                  <FontAwesomeIcon icon={faCodeFork} />
                  {data.forks_count}
                </div>
              </div>
            </>
          ) : (
            "No data available"
          )}
        </a>
      </div>
    </>
  );
}
