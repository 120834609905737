import styles from "../stylesheets/404.module.css";

export default function PageNotFound() {
  return (
    <>
      <div className={styles["page-container"]}>
        <div className={styles.centered}>
          <section>
            <h1 id={styles.NotFoundNumber}>404</h1>
            <h2 id={styles.NotFoundText}>Page Not Found</h2>
          </section>
        </div>
        <span className="styles.circleorbitcontainer styles.centered">
          <span className={styles.rightorbit}>
            <span className={styles.rightcircles}></span>
            <span className={styles.rightcircles}></span>
          </span>
        </span>
        <span className="styles.circleorbitcontainer styles.centered">
          <span className={styles.leftorbit}>
            <span className={styles.leftcircles}></span>
            <span className={styles.leftcircles}></span>
          </span>
        </span>
      </div>
    </>
  );
}
