export default function Home() {
  return (
    <>
      <section>
        <h1>Content</h1>
        <p className="centered-text">Make sure to check the About Me!</p>

        <p className="appear-left">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu
          consequat ac felis. Tortor posuere ac ut consequat semper viverra nam
          libero. Vitae tempus quam pellentesque nec nam aliquam sem. Id
          consectetur purus ut faucibus pulvinar elementum integer enim neque.
          Risus nec feugiat in fermentum posuere urna nec tincidunt praesent.
          Nunc sed id semper risus in hendrerit gravida. Accumsan in nisl nisi
          scelerisque. Hendrerit gravida rutrum quisque non tellus orci ac
          auctor augue. Cursus in hac habitasse platea dictumst quisque sagittis
          purus. Eu volutpat odio facilisis mauris sit amet massa vitae.
        </p>

        <p className="appear-right">
          Eget est lorem ipsum dolor sit amet. Tellus orci ac auctor augue
          mauris augue neque gravida in. Neque aliquam vestibulum morbi blandit.
          Lectus magna fringilla urna porttitor rhoncus dolor. Posuere ac ut
          consequat semper viverra nam libero justo. Enim neque volutpat ac
          tincidunt vitae semper quis. Porttitor rhoncus dolor purus non enim
          praesent. Mauris cursus mattis molestie a iaculis. Scelerisque mauris
          pellentesque pulvinar pellentesque habitant morbi tristique senectus.
          Turpis egestas pretium aenean pharetra magna ac placerat.
        </p>

        <p className="appear-left">
          Sem et tortor consequat id porta nibh venenatis. In eu mi bibendum
          neque egestas. Gravida in fermentum et sollicitudin ac orci phasellus
          egestas tellus. Mattis enim ut tellus elementum sagittis vitae et leo
          duis. Fermentum odio eu feugiat pretium nibh ipsum consequat nisl vel.
          Vel eros donec ac odio tempor orci. Ut placerat orci nulla
          pellentesque dignissim enim sit amet. Consectetur a erat nam at lectus
          urna duis. Amet cursus sit amet dictum sit amet. Purus sit amet
          volutpat consequat mauris nunc congue. Faucibus et molestie ac feugiat
          sed lectus. Pulvinar neque laoreet suspendisse interdum consectetur
          libero. Bibendum at varius vel pharetra. Praesent tristique magna sit
          amet purus gravida quis blandit turpis. Donec massa sapien faucibus
          et. Vel risus commodo viverra maecenas accumsan. Nunc eget lorem dolor
          sed viverra ipsum nunc.
        </p>

        <p className="appear-right">
          In aliquam sem fringilla ut morbi tincidunt augue interdum velit.
          Lacus sed viverra tellus in. Eget nullam non nisi est sit amet
          facilisis magna. Purus faucibus ornare suspendisse sed nisi lacus sed.
          Vitae congue eu consequat ac felis donec. Aenean pharetra magna ac
          placerat vestibulum lectus mauris ultrices eros. Sagittis orci a
          scelerisque purus semper eget duis. Ultrices sagittis orci a
          scelerisque purus semper eget duis. Sed cras ornare arcu dui. Sit amet
          cursus sit amet dictum sit amet. Et pharetra pharetra massa massa
          ultricies mi quis. Dignissim suspendisse in est ante in nibh mauris.
          Consequat mauris nunc congue nisi vitae suscipit tellus. Ut ornare
          lectus sit amet. Commodo sed egestas egestas fringilla phasellus
          faucibus scelerisque. Tempus egestas sed sed risus pretium. Vitae
          auctor eu augue ut lectus arcu. Vel fringilla est ullamcorper eget.
          Sed lectus vestibulum mattis ullamcorper. Pretium nibh ipsum consequat
          nisl vel.
        </p>

        <p className="appear-left">
          Congue eu consequat ac felis donec et odio. Placerat in egestas erat
          imperdiet sed. Egestas purus viverra accumsan in nisl nisi scelerisque
          eu. Proin sagittis nisl rhoncus mattis rhoncus urna neque. Nunc mattis
          enim ut tellus. Lacus luctus accumsan tortor posuere ac ut consequat
          semper viverra. Magna ac placerat vestibulum lectus mauris ultrices
          eros in. Ipsum dolor sit amet consectetur. Consectetur adipiscing elit
          ut aliquam purus sit amet. Non curabitur gravida arcu ac tortor
          dignissim. Integer enim neque volutpat ac tincidunt vitae semper quis
          lectus. In arcu cursus euismod quis viverra nibh. Tellus integer
          feugiat scelerisque varius morbi enim nunc faucibus a.
        </p>

        <p className="appear-right">
          Amet luctus venenatis lectus magna. Commodo viverra maecenas accumsan
          lacus vel facilisis volutpat. Ornare massa eget egestas purus viverra
          accumsan in. Sollicitudin nibh sit amet commodo nulla. Ac tortor
          dignissim convallis aenean. Est ante in nibh mauris. Sollicitudin
          tempor id eu nisl nunc mi. Ullamcorper eget nulla facilisi etiam
          dignissim diam quis. Nibh nisl condimentum id venenatis. Vel elit
          scelerisque mauris pellentesque pulvinar pellentesque. Enim lobortis
          scelerisque fermentum dui faucibus in ornare quam. Quis auctor elit
          sed vulputate mi sit.
        </p>
      </section>
    </>
  );
}
