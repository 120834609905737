import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Layout.tsx";
import Home from "./pages/Home.tsx";
import About from "./pages/About.tsx";
import PageNotFound from "./pages/PageNotFound.tsx";
import Projects from "./pages/Projects.tsx";
import Testing from "./pages/Testing.tsx";
const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="aboutme" element={<About />} />
        <Route path="projects" element={<Projects />} />
        <Route path="testing" element={<Testing />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
